<template>
  <div>
    <load-profile></load-profile>
    <b-card>
      <validation-observer ref="createForm" #default="{ invalid }">
        <n-form-confirmation
          key="createForm"
          @submit="submit"
          :form="$refs.createForm"
          :disabled="invalid"
        >
          <n-input :fields="fields" v-model="data">
            <template #businessGalleries>
              <div class="mt-2">
                <draggable
                  v-model="tempBussinessGalleries"
                  :move="onMove"
                  :start="true"
                  :end="true"
                >
                  <transition-group type="transition" :name="'flip-list'">
                    <div
                      class="list-group-item"
                      v-for="(gallery, idx) in tempBussinessGalleries"
                      :key="gallery.id"
                    >
                    <validation-provider
                        #default="{ errors }"
                        :vid="`${gallery.id}-${idx}`"
                        :name="$t('field.image')"
                        rules="required"
                      >
           
                        <n-async-single-image-uploader
                          :key="`image-upload-${idx}-${key}`"
                          path="business-gallery"
                          ph="240"
                          pw="240"
                          :disableUploadHistory="true"
                          :disableSpacer="false"
                          :fullWidth="false"
                          :image="gallery.image"
                          v-model="gallery.imageId"
                          @change="onUploadChange($event, idx, 'gallery')"
                          :class="errors.length > 0 ? 'is-invalid' : null"
                        ></n-async-single-image-uploader>
                        <feather-icon
                    
                          @click="removeGallery(idx)"
                          class="close-icon"
                          icon="XIcon"
                          size="18"
                        ></feather-icon>
              
                    </validation-provider>
                    </div>
                  </transition-group>
                </draggable>
                <hr v-show="tempBussinessGalleries.length >=1" />
                <b-button
                  variant="primary"
                  @click="addMoreGallery"
                  class="mb-2"
                >
                  <feather-icon icon="PlusIcon" class="mr-1"></feather-icon>
                  <span>{{ $t("button.addGallery") }}</span>
                </b-button>
              </div>
            </template>
          </n-input>
          <b-row>
            <b-col cols="12" class="text-right">
              <n-button-save-option
                ref="btnSubmit"
                @submit="submit"
                @save="save"
                :loading="loading"
                :resource="resource"
                :route="route"
                v-if="$can('create', resource)"
              ></n-button-save-option>
              <n-button-loading
                type="button"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                :loading="loading"
                class="mt-1"
                @submit="back"
              >
                {{ $t("button.back") }}
              </n-button-loading>
            </b-col>
          </b-row>
        </n-form-confirmation>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { BRow, BCol, BCard, BFormInput,BButton } from "bootstrap-vue";
import Repository from "@/repositories/RepositoryFactory";
import Ripple from "vue-ripple-directive";
import NButtonLoading from "@/components/NButtonLoading";
import NFormConfirmation from "@/components/NFormConfirmation";
import NInput from "@/components/NInput";
import FormInput from "./formInput";
import NButtonSaveOption from "@/components/NButtonSaveOption";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import NAsyncSingleImageUploader from "@/components/NAsyncSingleImageUploader";
import draggable from "vuedraggable";
const BusinessRepository = Repository.get("business");

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BFormInput,
    draggable,
    NButtonLoading,
    NFormConfirmation,
    NInput,
    NButtonSaveOption,
    FeatherIcon,
    NAsyncSingleImageUploader,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      key: 1,
      index: 1,
      tempBussinessGalleries: [],
      data: {
        bannerId: null,
        thumbnailId: null,
        logoId: null,
        featureImageId: null,
        nameEn: null,
        nameKm: null,
        link: null,
        sequenceOrder: null,
        isEnable: true,
 
        descriptionEn:"",
        metaTitleEn: null,
        metaDescriptionEn: null,
        contactInformationEn: "",

        descriptionKm: "",
        metaTitleKm: null,
        metaDescriptionKm: null,
        contactInformationKm: "",
        businessContents: [],
        businessGalleries: [],
      },
      loading: false,
    };
  },
  methods: {
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      return (
        (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
      );
    },
    onUploadChange(event, idx, type) {
        const index = this.tempBussinessGalleries.findIndex((element, i) => {
          return idx == i;
        });
        if (index != -1) {
          this.tempBussinessGalleries[index].image = event.fileUrl;
        }
    
    },
    submit(type) {
      this.$refs.createForm.validate().then((success) => {
        if (success) {
          this.$refs.btnSubmit.confirm(type);
        }
      });
    },
    save(type) {
      this.loading = true;
      this.data.businessContents.push(
        {
          locale: "en",
          description: this.data.descriptionEn,
          metaTitle: this.data.metaTitleEn,
          metaDescription: this.data.metaDescriptionEn,
          contactInformation: this.data.contactInformationEn,
        },
        {
          locale: "km",
          description: this.data.descriptionKm,
          metaTitle: this.data.metaTitleKm,
          metaDescription: this.data.metaDescriptionKm,
          contactInformation: this.data.contactInformationKm,
        },
      )
      this.tempBussinessGalleries.forEach((gallery, index) => {
        this.data.businessGalleries.push({
          imageId: gallery.imageId,
          sequenceOrder: index + 1,
        });
      });
      BusinessRepository.create(this.data)
        .then((response) => {
          this.$refs.btnSubmit.afterSave(type, response.data.data.id);
        })
        .catch((error) => {
          if (error.response?.status == 422) {
            this.$refs.createForm.setErrors(error.response?.data?.message);
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
    addMoreGallery() {
      this.index++;
      let gallery = {
        id: this.index,
        imageId: null,
        image: "",
      };
      this.tempBussinessGalleries.push(gallery);
    },
    removeGallery(idx) {
      this.tempBussinessGalleries.splice(idx, 1);
      this.key++;
    },

    back() {
      this.$router.push({
        name: `list-${this.route}`,
      });
    },
  },
  setup() {
    const fields = FormInput;
    const resource = "business";
    const route = "business";
    return { fields, resource, route };
  },
};
</script>  
